<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page: {
    title: "Starter page",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/"
        },
        {
          text: "Starter page",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h1 class="text-center mt-2 text-bold">Seja Bem vindo!</h1>
      <img src="@/assets/images/logo-dark.png" width="50%">
    </div>
  </Layout>
</template>